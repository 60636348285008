<script>
    export default {
        name: 'SpeakersBlock',
        data() {
            return {
                showSpeakers: false,
                showOldSpeakers: false,
            }
        },
        methods: {
            showClose() {
                this.showSpeakers = !this.showSpeakers;
            },
            showOldClose() {
                this.showOldSpeakers = !this.showOldSpeakers;
            }
        }
    }
</script>

<template>
    <b id="speakers"><p class="d-flex justify-center" style="margin-top: 40px; text-align: center; font-size: 1.2em;">Уникальная возможность познакомиться с
        известными спикерами <br>во время форума<img v-on:click="showClose()" src="../assets/arrow-black.png" style="height: fit-content; margin-left: 10px" :class="showSpeakers?'rotate':''" alt=""></p></b>
    <div class="container pt-0 pb-0">
        <div class="speakers--wrapper">
            <div class="speaker--wrapper">
                <img src="../assets/speakers/1.png" alt="Айгуль ДЖАКЕЛОВА">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Айгуль<br>ДЖАКЕЛОВА</span>
                    <span class="speaker-position">к.м.н., CEO SOS Medical Assistance</span>
                </div>
            </div>
            <div class="speaker--wrapper">
                <img src="../assets/speakers/2.png" alt="Алтын АРИНГАЗИНА">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Алтын<br>АРИНГАЗИНА</span>
                    <span class="speaker-position">Эксперт ВОЗ,<br>Президент Фулбрайтовской<br>Ассоциации Казахстана, д.м.н</span>
                </div>
            </div>
            <div class="speaker--wrapper">
                <img src="../assets/speakers/3.png" alt="Асель ТЕРЛИКБАЕВА">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Асель<br>ТЕРЛИКБАЕВА</span>
                    <span class="speaker-position">Директор ЦИГЗЦА<br>при Колумбийском<br>университете</span>
                </div>
            </div>
            <div class="speaker--wrapper">
                <img src="../assets/speakers/4.png" alt="Елжан Биртанов">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Елжан<br>БИРТАНОВ</span>
                    <span class="speaker-position">Советник<br>в TANSAR CAPITAL<br>и KazMedConsult</span>
                </div>
            </div>
            <div class="speaker--wrapper">
                <img src="../assets/speakers/5.png" alt="Вячеслав ЛОКШИН">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Вячеслав<br>ЛОКШИН</span>
                    <span class="speaker-position">д.м.н., Академик НАН РК, <br>СEO PERSONA</span>
                </div>
            </div>
            <div v-if="showSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/6.png" alt="Станислав КОНОВАЛОВ">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Станислав<br>КОНОВАЛОВ</span>
                    <span class="speaker-position">ALFRESA</span>
                </div>
            </div>
            <div v-if="showSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/7.png" alt="Жибек ЖОЛДАСОВА">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Жибек<br>ЖОЛДАСОВА</span>
                    <span class="speaker-position">Врач, директор UBC</span>
                </div>
            </div>
            <div v-if="showSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/8.png" alt="Вячеслав БЕЛОУСОВ">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Вячеслав<br>БЕЛОУСОВ</span>
                    <span class="speaker-position">Директор TREEGENE, к.б.н.</span>
                </div>
            </div>
            <div v-if="showSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/9.png" alt="Бексултан ШАКИМ">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Бексултан<br>ШАКИМ</span>
                    <span class="speaker-position">Операционный директор<br>Cerebra.AI</span>
                </div>
            </div>
            <div v-if="showSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/10.png" alt="Алмаз ШАРМАН">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Алмаз<br>ШАРМАН</span>
                    <span class="speaker-position">член Американской Ассоциации Здравоохранения, президент Академии профилактической медицины Казахстана и Национального центра здорового питания</span>
                </div>
            </div>
            <div v-if="showSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/11.png" alt="Лейла ДАИРБАЕВА">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Лейла<br>ДАИРБАЕВА</span>
                    <span class="speaker-position">Директор сети МЦ<br>эпилептологии и ЭЭГ</span>
                </div>
            </div>
            <div v-if="showSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/12.png" alt="Ерлан СУЛЕЙМЕНОВ">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Ерлан<br>СУЛЕЙМЕНОВ</span>
                    <span class="speaker-position">Учредитель КДЛ «Олимп»</span>
                </div>
            </div>
            <div v-if="showSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/13.png" alt="Галымжан ТОГИЗБАЕВ">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Галымжан<br>ТОГИЗБАЕВ</span>
                    <span class="speaker-position">Председатель Республиканского общественного<br>объединения "Казахская коллегия ревматологии"</span>
                </div>
            </div>
            <div v-if="showSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/14.png" alt="Жулдыз КОЖАХАНОВА">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Жулдыз<br>КОЖАХАНОВА</span>
                    <span class="speaker-position">врач-аллерголог,<br>основатель Galamilk</span>
                </div>
            </div>
            <div v-if="showSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/15.png" alt="Юрий СКИБА">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Юрий<br>СКИБА</span>
                    <span class="speaker-position">Представитель QazBioPharm,<br>к.б.н. </span>
                </div>
            </div>
            <div v-if="showSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/16.png" alt="Амантур РЫСКУЛОВ">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Амантур<br>РЫСКУЛОВ</span>
                    <span class="speaker-position">Генеральный директор<br>Diaverum Казахстан</span>
                </div>
            </div>
            <div v-if="showSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/17.png" alt="Евгений МУХАМЕДЖАНОВ">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Евгений<br>МУХАМЕДЖАНОВ</span>
                    <span class="speaker-position">Управляющий директор<br>«Казахстанской фондовой биржи»,<br>основатель стартап ECO Network</span>
                </div>
            </div>
            <div v-if="showSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/18.png" alt="Вячеслав ПОЦЕЛУЙКО">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Вячеслав<br>ПОЦЕЛУЙКО</span>
                    <span class="speaker-position">Советник председателя правления<br>АО КСЖ «Коммеск-Омир»</span>
                </div>
            </div>
            <div v-if="showSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/19.png" alt="Валихан ТЕН">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Валихан<br>ТЕН</span>
                    <span class="speaker-position">CEO KRIDA Fitness Club,<br>двухкратный чемпион мира<br>по пауэрлифтингу</span>
                </div>
            </div>
            <div v-if="showSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/20.png" alt="Асем МУСАБАЕВА">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Асем<br>МУСАБАЕВА</span>
                    <span class="speaker-position">к.м.н., Директор ГКП на ПХВ<br>Городская клиника №9,<br></span>
                </div>
            </div>
        </div>
    </div>

    <b><p class="d-flex justify-center" style="text-align: center; font-size: 1.2em;">Посмотреть спикеров прошлых лет<img v-on:click="showOldClose()" src="../assets/arrow-black.png" style="height: fit-content; margin-left: 10px" :class="showOldSpeakers?'rotate':''" alt=""></p></b>
    <div class="container pt-0">
        <div class="speakers--wrapper">
            <div class="speaker--wrapper">
                <img src="../assets/speakers/1.png" alt="Айгуль ДЖАКЕЛОВА">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Айгуль<br>ДЖАКЕЛОВА</span>
                    <span class="speaker-position">к.м.н., CEO SOS Medical Assistance</span>
                </div>
            </div>
            <div class="speaker--wrapper">
                <img src="../assets/speakers/5.png" alt="Вячеслав ЛОКШИН">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Вячеслав<br>ЛОКШИН</span>
                    <span class="speaker-position">д.м.н., Академик НАН РК, <br>CEO Persona</span>
                </div>
            </div>
            <div class="speaker--wrapper">
                <img src="../assets/speakers/21.png" alt="Расул ДЮСЕНОВ">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Расул<br>ДЮСЕНОВ</span>
                    <span class="speaker-position">Исполнительный директор<br>ОИПЮЛ «Ассоциация<br>клиник Алматы»</span>
                </div>
            </div>
            <div class="speaker--wrapper">
                <img src="../assets/speakers/22.png" alt="Тлеухан АБИЛДАЕВ">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Тлеухан<br>АБИЛДАЕВ</span>
                    <span class="speaker-position">Руководитель филиала<br>НАО «Фонд социального<br>медицинского страхования»</span>
                </div>
            </div>
            <div class="speaker--wrapper">
                <img src="../assets/speakers/23.png" alt="Жанар ШАЛХАРОВА">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Жанар<br>ШАЛХАРОВА</span>
                    <span class="speaker-position">Член Правления Союза<br>медицинских колледжей<br>Казахстана, генеральный директор<br>Высшего многопрофильного<br>медицинского колледжа «Туркестан»</span>
                </div>
            </div>

            <div v-if="showOldSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/24.png" alt="Марат КАИРЛЕНОВ">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Марат<br>КАИРЛЕНОВ</span>
                    <span class="speaker-position">Директор консалтинговой<br>компании Ulagat Consulting Group</span>
                </div>
            </div>
            <div v-if="showOldSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/25.png" alt="Мынжылкы БЕРДИХОДЖАЕВ">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Мынжылкы<br>БЕРДИХОДЖАЕВ</span>
                    <span class="speaker-position">Заместитель директора <br>Больницы Медицинского<br>центра Управления<br>делами Президента РК</span>
                </div>
            </div>
            <div v-if="showOldSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/26.png" alt="Гульнара ОТЕПОВА">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Гульнара<br>ОТЕПОВА</span>
                    <span class="speaker-position">Председатель <br>«ОЮЛ Казахстанская<br>ассоциация  частных<br>медицинских структур»</span>
                </div>
            </div>
            <div v-if="showOldSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/27.png" alt="Серик МЕНДЫКУЛОВ">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Серик<br>МЕНДЫКУЛОВ</span>
                    <span class="speaker-position">Директор Merabel Clinic</span>
                </div>
            </div>
            <div v-if="showOldSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/28.png" alt="Еркебулан БУРКИТБАЕВ">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Еркебулан<br>БУРКИТБАЕВ</span>
                    <span class="speaker-position">Заведующий Юридическим отделом<br>Казахстанского отраслевого профсоюза<br>работников здравоохранения «AQNIET»</span>
                </div>
            </div>

            <div v-if="showOldSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/29.png" alt="Аида АЛЬЖАНОВА">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Аида<br>АЛЬЖАНОВА</span>
                    <span class="speaker-position">PhD докторант<br>по специальности менеджмент</span>
                </div>
            </div>
            <div v-if="showOldSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/30.png" alt="Амантур РЫСКУЛОВ">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Амантур<br>РЫСКУЛОВ</span>
                    <span class="speaker-position">Генеральный директор<br>Diaverum</span>
                </div>
            </div>
            <div v-if="showOldSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/31.png" alt="Зафар ВАХИДОВ">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Зафар<br>ВАХИДОВ</span>
                    <span class="speaker-position">Партнер<br>ТОО «Vakhidov and Partners»</span>
                </div>
            </div>
            <div v-if="showOldSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/32.png" alt="Станислав КОНОВАЛОВ">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Станислав<br>КОНОВАЛОВ</span>
                    <span class="speaker-position">Главный эксперт<br>по программе HTSA Plus<br>в странах СНГ</span>
                </div>
            </div>
            <div v-if="showOldSpeakers"  class="speaker--wrapper">
                <img src="../assets/speakers/33.png" alt="Руслан ХАМИТОВ">
                <div class="d-flex justify-center align-items-center direction-column">
                    <span class="speaker-name">Руслан<br>ХАМИТОВ</span>
                    <span class="speaker-position">Технический директор<br>CheckDoc</span>
                </div>
            </div>

        </div>
        <div class="d-flex justify-center">
            <button class="btn btn-gradient">СТАТЬ СПИКЕРОМ</button>
        </div>
    </div>

</template>

<style scoped>
    .speaker-name, .speaker-position {
        color: #00464E;
    }
</style>