<script>
    export default {
        name: 'StatsBlock',
        props: ['isMobile']
    }

    document.addEventListener("DOMContentLoaded", function() {
        // You can change this class to specify which elements are going to behave as counters.
        var elements = document.querySelectorAll(".scroll-counter")

        elements.forEach(function(item) {
            // Add new attributes to the elements with the '.scroll-counter' HTML class
            item.counterAlreadyFired = false
            item.counterSpeed = item.getAttribute("data-counter-time") / 45
            item.counterTarget = +item.innerText
            item.counterCount = 0
            item.counterStep = item.counterTarget / item.counterSpeed

            item.updateCounter = function() {
                item.counterCount = item.counterCount + item.counterStep
                item.innerText = Math.ceil(item.counterCount)

                if (item.counterCount < item.counterTarget) {
                    setTimeout(item.updateCounter, item.counterSpeed)
                } else {
                    item.innerText = item.counterTarget
                }
            }
        })

        // Function to determine if an element is visible in the web page
        var isElementVisible = function isElementVisible(el) {
            var scroll = window.scrollY || window.pageYOffset
            var boundsTop = el.getBoundingClientRect().top + scroll
            var viewport = {
                top: scroll,
                bottom: scroll + window.innerHeight,
            }
            var bounds = {
                top: boundsTop,
                bottom: boundsTop + el.clientHeight,
            }
            return (
                (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
                (bounds.top <= viewport.bottom && bounds.top >= viewport.top)
            )
        }

        // Funciton that will get fired uppon scrolling
        var handleScroll = function handleScroll() {
            elements.forEach(function(item) {
                if (true === item.counterAlreadyFired) return
                if (!isElementVisible(item)) return
                item.updateCounter()
                item.counterAlreadyFired = true
            })
        }

        // Fire the function on scroll
        window.addEventListener("scroll", handleScroll)
    })

</script>

<template>

    <div id="stats" class="container d-flex justify-around" style="padding: 20px 0">
        <div class="d-flex align-items-center gap-20">
            <span class="statistic"><span data-counter-time="1000" class="scroll-counter">20</span>+</span>
            <p class="statistic-text">спикеров</p>
        </div>
        <div class="d-flex align-items-center gap-20">
            <span data-counter-time="1000" class="statistic scroll-counter">8</span>
            <p class="statistic-text">часов <br>трансляции</p>
        </div>
        <div class="d-flex align-items-center gap-20">
            <span data-counter-time="1000" class="statistic scroll-counter">200</span>
            <p class="statistic-text">гостей <br>в оффлайн</p>
        </div>
    </div>
</template>

<style scoped>
    .statistic-text {
        color: black;
    }

    @media(max-width: 765px) {
        #stats {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
</style>