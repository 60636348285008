<script>
    export default  {
        name: 'NewsBlock'
    }
</script>

<template>

    <div class="container">
        <div class="news--wrapper">
            <div class="news-image">
                <img src="../assets/pressa1.png" alt="Обязанности и ответственность есть только
у медиков – врачи в Алматы обсудили свои проблемы">
            </div>
            <div>
                <h3 class="news-title">Обязанности и ответственность есть только
                    у медиков – врачи в Алматы обсудили свои проблемы</h3>
                <a class="news-link" href="https://orda.kz/obyazannosti-i-otvetstvennost-est-tolko-u-medikov-vrachi-v-almaty-obsudili-svoi-problemy/">Читать статью на orda.kz</a>
            </div>
        </div>
        <div class="news--wrapper">
            <div class="news-image">
                <img src="../assets/pressa2.png" alt="IV Kazakhstan Healthcare Forum: рост инвестиций
  и вызовы здравоохранения">
            </div>
            <div>
                <h3 class="news-title">IV Kazakhstan Healthcare Forum: рост инвестиций
                    и вызовы здравоохранения</h3>
                <a class="news-link" href="https://dknews.kz/ru/v-strane/293678-iv-kazakhstan-healthcare-forum-rost-investiciy-i">Читать статью на dknews.kz</a>
            </div>
        </div>
        <div class="news--wrapper">
            <div class="news-image">
                <img src="../assets/pressa3.png" alt="О главных темах прошедшего  Kazakhstan Healthcare Forum">
            </div>
            <div>
                <h3 class="news-title">
                    О главных темах прошедшего  Kazakhstan Healthcare Forum
                </h3>
                <a class="news-link" href="https://businessmir.kz/2023/06/20/o-glavnyh-temah-proshedshego-kazakhstan-healthcare-forum/">Читать статью на businessmir.kz</a>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>