<script>
import Modal from "@/components/Modal.vue";
import SearchComponent from "@/components/SearchComponent.vue";
export default {
    name: 'ForWho',
    components: {
        Modal,
        SearchComponent
    },
    data() {
        return {
            TariffSelectedTab: 0,
            windowWidth: 0,
            tariffTextMaxHeight: '170px',
            isModalOpened: false,
            selectedTariff: 0,
            format: 0,
            showModal: false,
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        selectTab(index) {
            this.TariffSelectedTab = index;
        },
        toggleText() {
            this.tariffTextMaxHeight = this.tariffTextMaxHeight === 'none' ? '170px' : 'none';
        },
        openModal(selectedTariff, format) {
            this.selectedTariff = selectedTariff
            this.format = format
            this.showModal = true;
        },
    }
}
</script>

<template>
    <div class="for-who">
        <div class="container">
            <modal :show="showModal" @close="showModal = false">
                <template #body>
                    <SearchComponent @close="showModal=false" :selected-tariff="selectedTariff" :format="format"/>
                </template>
            </modal>
            <h1 class="block-title">ДЛЯ КОГО ФОРУМ?</h1>
            <div class="reg-grid">
                <div class="card">Руководители государственных <br>и частных медучреждений</div>
                <div class="card">Представители фармкомпаний</div>
                <div class="card">Представители<br>парамедицинского бизнеса</div>
                <div class="card">Эксперты<br>из технологического бизнеса</div>
                <div class="card">Представители госорганов</div>
                <div class="card">Врачи и медперсонал</div>
            </div>
            <h1 id="tariffs" class="block-title">БИЛЕТЫ</h1>
            <!--            <div style="background-image: url(../assets/tariff-bg-1920.png);width:100%;padding:30px;"></div>-->
            <div class="tariff-tabs">
                <div class="tariff-tab-header">
                    <div
                        :class="{ TariffSelectedTab : TariffSelectedTab === 0, TariffUnselectedTab : TariffSelectedTab !== 0}"
                        @click="selectTab(0)">
                        <span style="color: white; font-weight: bold">Оффлайн</span>
                    </div>

                    <div
                        :class="{ TariffSelectedTab : TariffSelectedTab === 1, TariffUnselectedTab : TariffSelectedTab !== 1}"
                        @click="selectTab(1)">
                        <span style="color: white; font-weight: bold">Онлайн</span>
                    </div>
                </div>
                <div v-if="TariffSelectedTab === 0" class="reg-grid mt-40">
                    <div class="tab">
                        <div class="tariff-card">
                            <span class="title">Standard</span>
<!--                                                        <span class="full-price">75 000 kzt</span>-->
                            <span class="full-price">50 000 kzt</span>
                            <span class="discount-price">25 000 kzt<span>   / до 1-го июня</span></span>
                            <div class="d-flex justify-center mt-40">
                            
                            </div>
                        </div>

                        <div class="d-flex mt-40" style="flex-direction: column; row-gap: 10px; padding-left: 30px;">
                            <div class="tariff-textline" style="font-family: 'Century Gothic',sans-serif">
                                <img alt="accept" src="../assets/accept.png">
                                <span>Презентации спикеров</span>
                            </div>

                            <div class="tariff-textline" style="font-family: 'Century Gothic',sans-serif">
                                <img alt="accept" src="../assets/accept.png">
                                <span>Нетворкинг и живое общение с гостями и участниками</span>
                            </div>
                            <div class="tariff-textline" style="font-family: 'Century Gothic',sans-serif">
                                <img alt="accept" src="../assets/accept.png">
                                <span>Скидка 15% на 3 и более билетов от компании</span>
                            </div>

                            <div class="tariff-textline" style="font-family: 'Century Gothic',sans-serif">
                                <img alt="accept" src="../assets/accept.png">
                                <span>Скидка 20% на 5 и более билетов от компании</span>
                            </div>
                        </div>
                    </div>
                    <div class="tab">
                        <div class="tariff-card pink">
                            <span class="title">VIP</span>
                            <span class="full-price" >100 000 kzt</span>
                            <span class="discount-price">50 000 kzt <span>/ до 1-го июня</span> </span>
                            <div class="d-flex justify-center mt-40">
                                
                            </div>
                        </div>
                        <div class="d-flex mt-40" style="flex-direction: column; row-gap: 10px; padding-left: 30px;">

                            <div class="tariff-textline">
                                <img alt="accept" src="../assets/accept.png">
                                <span>Презентации спикеров и запись форума</span>
                            </div>

                            <div class="tariff-textline">
                                <img alt="accept" src="../assets/accept.png">
                                <span>Нетворкинг и живое общение с гостями и участниками</span>
                            </div>

                            <div class="tariff-textline">
                                <img alt="accept" src="../assets/accept.png">
                                <span>Скидка 15% на 3 и более билетов от компании</span>
                            </div>

                            <div class="tariff-textline">
                                <img alt="accept" src="../assets/accept.png">
                                <span>Скидка 20% на 5 и более билетов от компании</span>
                            </div>

                            <div class="tariff-textline">
                                <img alt="accept" src="../assets/accept.png">
                                <span>Интервью для партнеров форума</span>
                            </div>

                            <div class="tariff-textline">
                                <img alt="accept" src="../assets/accept.png">
                                <span>Приоритет места в зале конференции</span>
                            </div>

                            <div class="tariff-textline">
                                <img alt="accept" src="../assets/accept.png">
                                <span>Обед со спикерами</span>
                            </div>

                            <div class="tariff-textline">
                                <img alt="accept" src="../assets/accept.png">
                                <span>Скидка на Kazakhstan Growth Forum 24 - 50%</span>
                            </div>

                        </div>
                    </div>
                </div>
                <div v-else class="reg-grid mt-40">
                    <div class="tab">
                        <div class="tariff-card">
                            <div class="title">Online +</div>
                            <span class="full-price" >10 000 kzt</span>
                            <span class="discount-price">5 000 kzt <span>/ до 1-го июня</span> </span>
                            <div class="d-flex justify-center mt-40">
                                
                            </div>
                        </div>
                        <div class="d-flex mt-40" style="flex-direction: column; row-gap: 10px; padding-left: 30px;">

                            <div class="tariff-textline">
                                <img alt="accept" src="../assets/accept.png">
                                <span>Подключение к трансляции</span>
                            </div>
                            <div class="tariff-textline">
                                <img alt="accept" src="../assets/accept.png">
                                <span>Презентации спикеров и запись форума</span>
                            </div>
                        </div>
                    </div>
                    <div class="tab">
                        <div class="tariff-card">
                            <div class="title">Online</div>
                            <div class="discount-price mt-30">Бесплатно</div>
                            <div class="d-flex justify-center mt-40">
                            
                            </div>
                        </div>
                        <div class="d-flex mt-40" style="flex-direction: column; row-gap: 10px; padding-left: 30px;">

                            <div class="tariff-textline">
                                <img alt="accept" src="../assets/accept.png">
                                <span>Подключение к трансляции</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

.tariff-tabs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 30px;
}

.tariff-textline {
    display: flex;
    gap: 10px;
    align-items: center;
}

.tariff-textline img {
    height: fit-content;
}
.tariff-tab-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E8EDF2;
    padding: 3px;
    border-radius: 8px;
}
.TariffSelectedTab {
    background: #CD50AE;
    border-radius: 8px;
    font-family: 'Mont', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 1rem;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 30px;
    cursor: pointer;
}

.reg-grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-rows: auto auto auto;
    gap: 50px;
    row-gap: 50px;
}
.TariffUnselectedTab {
    font-family: 'Mont', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    font-size: clamp(1rem, 0.9375rem + 0.3125vw, 1.3125rem);
    line-height: 30px;
    color: #B8CBDE;
    background: #E8EDF2;
    border-radius: 10px;
    padding: 10px 30px;
    cursor: pointer;
}

.tariff-card {
    width: 467px;
    height: 263px;
    background: whitesmoke;
    box-shadow: 0px 4px 57px #D3D8E3;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 10px;
    padding: 0 30px;
}
.tariff-card .discount-price{

}
.tariff-card.pink{
    background: #CD50AE;
}
.tariff-card
.title {
    color:#00464E;
    font-size: 2rem;
    font-weight: bold;

}
.tariff-card.pink
.title {
    color: white;
}

span {
    font-style: normal;
    line-height: 28px;
    letter-spacing: 0.396px;
    font-weight: 400;
    color: #3E3D3D;
}
.full-price {
    position: relative;
    width: 100%;
    font-size: 1rem;
    color: #6E708C;

    &:before {
        content: "";
        border-bottom: 1px solid #000000;
        position: absolute;
        width: 25%;
        height: 40%;
        transform: rotate(-3.85deg);
    }
}
.discount-price {
    font-weight: 700;
    font-size: 44px;
    white-space: nowrap;

    span {
        font-size: 1rem;
        line-height: 25px;
        letter-spacing: 0.34px;
        white-space: nowrap;
    }
}

@media(max-width: 1200px) {
    .reg-grid {
        display: grid;
        grid-template-columns: auto;
        row-gap: 20px;
    }
    .tariff-card {
        width: inherit;
    }
}
</style>