<script>
    export default {
        name: "GalleryBlock",
        data() {
            return {
                toggle: false,
                imgs: [
                    {
                        id: 1,
                        path: require('../assets/gallery1.webp')
                    },
                    {
                        id: 2,
                        path: require('../assets/gallery2.webp')
                    },
                    {
                        id: 3,
                        path: require('../assets/gallery3.webp')
                    },
                    {
                        id: 4,
                        path: require('../assets/gallery4.webp')
                    },
                    {
                        id: 5,
                        path: require('../assets/gallery5.webp')
                    },
                    {
                        id: 6,
                        path: require('../assets/gallery6.webp')
                    },
                    {
                        id: 7,
                        path: require('../assets/gallery7.webp')
                    },
                    {
                        id: 8,
                        path: require('../assets/gallery8.webp')
                    },
                    {
                        id: 9,
                        path: require('../assets/gallery9.webp')
                    },
                    {
                        id: 10,
                        path: require('../assets/gallery10.webp')
                    },
                    {
                        id: 11,
                        path: require('../assets/gallery11.webp')
                    },
                    {
                        id: 12,
                        path: require('../assets/gallery12.webp')
                    },
                    {
                        id: 13,
                        path: require('../assets/gallery13.webp')
                    },
                    {
                        id: 14,
                        path: require('../assets/gallery14.webp')
                    },
                    {
                        id: 15,
                        path: require('../assets/gallery15.webp')
                    },
                    {
                        id: 16,
                        path: require('../assets/gallery16.webp')
                    },
                    {
                        id: 17,
                        path: require('../assets/gallery17.webp')
                    },
                    {
                        id: 18,
                        path: require('../assets/gallery18.webp')
                    },
                ]
            }
        },
        methods: {
            toggleBtn() {
                this.toggle = !this.toggle
            }
        }
    }
</script>

<template>

    <div id="gallery" class="container mb-50">
        <div class="d-flex justify-around mb-30">
            <button class="btn btn-gradient" @click="toggleBtn" style="width: 30%;">ФОТО</button>
            <button class="btn btn-gradient" @click="toggleBtn" style="width: 30%;">ВИДЕО</button>
        </div>

        <div v-if="toggle" class="d-flex justify-center">
            <iframe class="video-player" src="https://www.youtube.com/embed/NXuhaqoKP8s?si=QTzR2c1rOJCE549_" title="KHG 2023">
            </iframe>
        </div>

        <div v-if="!toggle">
            <v-carousel hide-delimiters
                        cycle>
                <template v-slot:prev="{ props }">
                    <v-btn icon="left"
                        @click="props.onClick"
                    ><i class="mdi--chevron-left"/></v-btn>
                </template>
                <template v-slot:next="{ props }">
                    <v-btn icon="right"
                        @click="props.onClick"
                    ><i class="mdi--chevron-right"/></v-btn>
                </template>
                <v-carousel-item
                v-for="(item,i) in imgs"
                :key="i"
                :src="item.path"
                ></v-carousel-item>
            </v-carousel>
        </div>

    </div>
    
</template>

<style scoped>
.mdi--chevron-right {
    display: inline-block;
    width: 1em;
    height: 1em;
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6z'/%3E%3C/svg%3E");
    background-color: currentColor;
    -webkit-mask-image: var(--svg);
    mask-image: var(--svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
}
.mdi--chevron-left {
    display: inline-block;
    width: 1em;
    height: 1em;
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M15.41 16.58L10.83 12l4.58-4.59L14 6l-6 6l6 6z'/%3E%3C/svg%3E");
    background-color: currentColor;
    -webkit-mask-image: var(--svg);
    mask-image: var(--svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
}
</style>