<script>

import {defineComponent, ref} from "vue";
import md5 from "crypto-js/md5";
import axios from "axios";
import {vMaska} from "maska";

const isAccepted = ref(false)
const first_name = ref("")
const last_name = ref("")
const email = ref("")
const phone = ref("")
const position = ref("")
const company = ref("")
const step = ref(1)
const qrBase64 = ref("")
const emitted = ref("kaspi")
const event_member_id = ref(0)
const price = ref(0)
const redirectUrl = ref("")
const tariffOptions = ref([
    {value: 18, text: "Vip", selected: true},
    {value: 17, text: "Standard", selected: false},
    {value: 19, text: "Online+", selected: false},
    {value: 20, text: "Online", selected: false},
])
const paid = ref(false)

export default defineComponent({
    name: "SearchComponent",
    directives: { maska: vMaska },
    props: {
        selectedTariff: Number,
        format: Number,
    },
    data() {
        return {
            isAccepted,
            first_name,
            last_name,
            email,
            phone,
            position,
            company,
            step,
            qrBase64,
            emitted,
            event_member_id,
            price,
            redirectUrl,
            tariffOptions,
            selected: this.selectedTariff,
            paid,
            windowWidth: window.innerWidth,
            promocode: "",
        }
    },
    methods: {

        validatePhone() {
            if (!/^\+\d\(\d\d\d\)\d\d\d-\d\d-\d\d$/.test(this.phone)) {
                return false;
            } else {
                return true;
            }
        },
        validateEmail() {
            let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            this.email = this.email.replace(/\s/g, '');
            return (this.email == "")? "" : (reg.test(this.email)) ? true : false
        },
        search() {
            if (this.isAccepted === false) {
                this.showMessage('Примите условия', "error")
                return
            }

            if (!this.validateEmail()) {
                this.showMessage('Неправильный email', "error")
                return
            }
            if (!this.validatePhone()) {
                this.showMessage('Неправильный номер телефона', "error")
                return
            }
            let hashCode = md5(this.email + "/k19&ajk")
            // let message = "";
            var phone = this.phone.replace(/[^\w\s]/gi, '')
            axios.get("https://kgf.cic.kz/api/check-member-registration?last_name=" + this.last_name + "&email=" + this.email + "&phone=" + phone + "&hash=" + hashCode + "&event_id=19")
                .then(response => {
                    // message = response.data.message
                    if (response.data.data) {
                        axios.get("https://kgf.cic.kz/api/get-member-by-email-and-phone?last_name=" + this.last_name + "&phone=" + phone + "&email=" + this.email + "&hash=" + hashCode)
                            .then(response => {
                                if (response.data.data != null) {
                                    this.first_name = response.data.data.first_name
                                    this.last_name = response.data.data.last_name
                                    this.email = response.data.data.email
                                    this.phone = response.data.data.phone
                                    this.position = response.data.data.position
                                    this.company = response.data.data.company
                                }
                                this.step = 2;
                                // console.log(response)
                            })
                            .catch(() => {
                                // console.log(error)
                            })
                    } else {
                        this.showMessage(response.data.message, 'success');
                    }
                })
                .catch(() => {
                    // console.log(error)
                    // message = error.message
                })
            // console.log(message);
        },
        registration() {
            let data = {
                "event_id": 24,
                "format": this.format,
                "promocode": this.promocode,
                "first_name": this.first_name,
                "last_name": this.last_name,
                "email": this.email,
                "phone": this.phone,
                "position": this.position,
                "company": this.company,
                "tariff_id": this.selected,
                "utm": localStorage.getItem('utm')
            }

            if (!this.validateEmail()) {
                this.showMessage(this.$t('search_component.item2'), "error")
                return
            }
            if (!this.validatePhone()) {
                this.showMessage(this.$t('search_component.item3'), "error")
                return
            }
            if (this.first_name.length === 0 || this.last_name.length === 0 || this.email.length === 0 || this.phone.length === 0 || this.position.length === 0 || this.company.length === 0) {
                alert(this.$t('search_component.item4'))
            } else if (this.isAccepted === false) {
                alert(this.$t('search_component.item5'))
            } else if (this.selected === 0) {
                alert(this.$t('search_component.item6'))
            }

            axios.post("https://kgf.cic.kz/api/registrate", data)
                .then(response => {
                    this.event_member_id = response.data.data.event_id
                    this.price = response.data.data.price

                    if (this.price === 0) {
                        this.step = 5
                    } else {
                        this.step = 3
                    }
                    if (window.ScarabQueue !== undefined) {
                        window.ScarabQueue.push(['tag', 'kmc-registration', {
                            tariff_id: this.selected,
                            promocode: this.promocode,
                            first_name: this.first_name,
                            last_name: this.last_name,
                            email: this.email,
                            phone: this.phone,
                            position: this.position,
                            company: this.company,
                        }]);
                        window.ScarabQueue.push(['go']);
                    }
                })
                .catch(error => {
                    this.showMessage(error.response.data.message, 'error')
                })
        },
        selectPaymentMethod() {
            if (this.emitted === "kaspi") {
                if(window.ScarabQueue!=undefined) {
                    window.ScarabQueue.push(['tag', 'kmc-pay', {
                        item_name: 'kaspi',
                        price: this.price,
                    }]);

                    window.ScarabQueue.push(['go']);
                }
                axios.get('https://kgf.cic.kz/api/v1/event/' + this.event_member_id + '/pay')
                    .then(response => {
                        this.qrBase64 = response.data.qrCodeImage
                        this.redirectUrl = response.data.redirectUrl

                        // this.step = 4
                        function isMobile() {
                            const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
                            // console.log(regex.test(navigator.userAgent));
                            return regex.test(navigator.userAgent);
                        }

                        if (isMobile()) {
                            window.location.href = this.redirectUrl
                        } else {
                            this.step = 4;
                        }
                    })
            } else {
                if (window.ScarabQueue !== undefined) {
                    window.ScarabQueue.push(['tag', 'kmc-pay', {
                        item_name: 'epay',
                        price: this.price,
                    }]);
                    window.ScarabQueue.push(['go']);
                }
                window.location.href = 'https://kgf.cic.kz/api/v1/epay/pay?amount=' + this.price + '&event_member_id=' + this.event_member_id;
            }
        },
        close() {
            this.first_name = "";
            this.last_name = "";
            this.step = 1;
            this.email = "";
            this.phone = "";
            this.position = "";
            this.company = "";
            this.selected = 0;
            this.paid = false;
            this.redirectUrl = "";
            this.qrBase64 = "";
            document.body.classList.remove("modal-open");
            // document.body.classList.remove("modal-open");
            this.$emit('close');
        },
        back() {
            this.step--;
        },

        showMessage(message, type) {
            this.$toast.show(message, {
                type: type,
                duration: 2500,
                position: 'top'
            })
        }
    },
    emits: ['close'],
    watch: {
        // event_member_id(member_id) {
            // this.$pusher.logToConsole = true;
            //
            // const channel = this.$pusher.subscribe('payment.' + this.event_member_id);
            //
            // channel.bind('App\\Events\\PaymentStatusUpdated', (data) => {
            //     // console.log(data)
            //     // console.log(member_id)
            //     if (member_id === data.eventMemberId) {
            //         this.paid = true;
            //     }
            // });
        // }
    },
})
</script>

<template>
    <div>
        <span class="close z2" style="color: black"
              @click="close()">x
        </span>
        <div v-if="step !== 4 && step !== 5" class="reg-bg">
            <h2 class="modal-title">Регистрация</h2>
            <span class="modal-caption">Заполните все поля регистрационной формы</span>
            <form v-if="step===1" style="margin-top: 40px; margin-bottom: 40px" @submit.prevent="search">
                <div class="form-grid">
                    <div class="form-field w100">
                        <input type="text" id="last_name" v-model="last_name" required maxlength="30">
                        <label for="last_name" class="placeholder">Фамилия</label>
                    </div>
                    <div class="form-field w100">
                        <input type="text" id="email" v-model="email" required maxlength="50">
                        <label for="email" class="placeholder">Почта</label>
                    </div>
                    <div class="form-field w100">
                        <input type="text" id="phone" v-model="phone" v-maska data-maska="+7(###)###-##-##" required maxlength="20">
                        <label for="phone" class="placeholder" >Телефон</label>
                    </div>
                    <button class="btn w100" :disabled="!isAccepted" style="color: white"
                            >Найти
                    </button>
                </div>
                <div class="mt-40">
                    <input type="checkbox" id="accept" v-model="isAccepted">
                    <label for="accept" class="accept-font">
                    <span class="modal-caption">
                        Я даю согласие на обработку персональных данных, согласен на получение информационных рассылок от Kazakhstan Growth Forum и соглашаюсь c
                        <a href="" style="color:white; text-decoration: underline;">политикой конфиденциальности</a>
                    </span>
                    </label>
                </div>
            </form>
            <form v-else-if="step===2" style="margin-top: 20px; margin-bottom: 20px" @submit.prevent="registration">
                <div class="form-grid">
                    <div class="form-field w100">
                        <input id="s2-ln" type="text" v-model="last_name" required>
                        <label for="s2-ln" class="placeholder">Фамилия</label>
                    </div>
                    <div class="form-field w100">
                        <input id="s2-name" type="text" v-model="first_name" required>
                        <label for="s2-name" class="placeholder">Имя</label>
                    </div>
                    <div class="form-field w100">
                        <input id="s2-email" type="email" v-model="email" required>
                        <label for="s2-email" class="placeholder">Почта</label>
                    </div>

                    <div class="form-field w100">
                        <input id="s2-ph" type="text" v-model="phone" v-maska data-maska="+7(###)###-##-##"  required>
                        <label for="s2-ph" class="placeholder">Телефон</label>
                    </div>
                    <div class="form-field w100">
                        <input id="s2-co" type="text" v-model="company" required>
                        <label for="s2-co" class="placeholder">Компания</label>
                    </div>
                    <div class="form-field w100">
                        <input id="s2-co" type="text" v-model="position" required>
                        <label for="s2-po" class="placeholder">Должность</label>
                    </div>
                    <div class="form-field w100">
                        <input id="promocode" type="text" v-model="promocode">
                        <label for="promocode" class="placeholder">Промокод</label>
                    </div>
                    <div class="form-grid">
                        <button class="btn btn-primary" style="color: white; border-radius: 10px !important;" @click="back()">Назад</button>
                        <button class="btn" style="color: white">Далее
                        </button>
                    </div>
                </div>
            </form>
            <div v-else-if="step===3" style="margin-top: 20px">
                <div>
                    <span class="modal-caption">
                        Оплата прошла успешно
                    </span>
                    <div style="margin-bottom: 10px; margin-top: 10px;">
                        <input type="radio" id="kaspi" value="kaspi" name="emitted" v-model="emitted">
                        <label for="kaspi">
                        <span class="modal-caption">
                            Kaspi
                        </span>
                        </label>
                    </div>
                    <div style="margin-bottom: 30px;">
                        <input type="radio" id="epay" value="epay" name="emitted" v-model="emitted">

                        <label for="epay">
                        <span class="modal-caption">
                           Оплата картой
                        </span>
                        </label>
                    </div>
                    <div class="form-grid">
                        <button class="btn btn-primary" style="color: white" @click="back()">Назад</button>
                        <button class="btn" style="color: white;"
                                @click="selectPaymentMethod()">Оплатить
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="step===5" class="reg-bg">
            <div style="margin-top: 20px">
                <h2 class="modal-title" style="margin-bottom: 20px">
                    Спасибо за регистрацию
                </h2>
                <span class="modal-caption" style="margin-bottom: 40px">
                    Ваша регистрация прошла успешно
                </span>
            </div>
        </div>

        <div v-else class="qr-code-wrapper">
            <div class="qr-code-text-container">
                <h2 class="qr-title" >Для оплаты отсканируйте QR <br> в мобильном приложении Kaspi.kz</h2>
                <p class="qr-amount">Сумма к оплате:<br> {{ price }} тг</p>
            </div>
            <div class="cr-white-bg">
                <img v-if="paid===false" v-bind:src="'data:image/jpeg;base64,' + qrBase64" class="qr-code"
                     alt="QR-код для оплаты Kaspi.kz">
                <p v-else
                   style="position: relative; z-index: 3; width: 200px; height: 200px; font-family: 'PT Sans Caption'; font-size: 36px">
                    Оплата прошла успешно</p>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
$primary-color: #2B255C;
$modal-bg-color: rgba(0, 0, 0, 0.5);
$box-shadow-color: rgba(0, 0, 0, 0.33);
$border-color: #CFD3D8;
$btn-bg-color: #CD50AE;
$btn-border-color: #ee64cb;
$hover-shadow-color: #99bdff;
$font-family-main: 'Montserrat', sans-serif;
$font-family-title: 'PT Sans Caption', sans-serif;
$font-family-caption: 'GT Eesti Display Trial', sans-serif;
$focus-border-color: #666;
$placeholder-color: #aaa;

.form-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    row-gap: 20px;
}

.btn {
    cursor: pointer;
    font-family: $font-family-main;
    font-style: normal;
    font-weight: 800;
    font-size: 1.125rem;
    line-height: 20px;
    letter-spacing: 0.34px;
    color: $primary-color;
    width: 100%;
    height: 100%;
    padding: 10px;
    background: $btn-bg-color;
    border: 1px solid $btn-border-color;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        box-shadow: 8px 8px $hover-shadow-color;
        transition: .3s;
    }

    &:disabled {
        background-color: #D0D0D2 !important;
        color: #727272;
        cursor: not-allowed;
    }
}

label.accept-font {
    font-family: $font-family-title;
    font-style: normal;
    cursor: pointer;
}

input[type="checkbox"] {
    width: 15px;
    background: #FFFFFF;
    border: 1px solid $border-color;
    box-sizing: border-box;
    border-radius: 6px;
    margin-right: 10px;
}

.form-field {
    position: relative;
    //margin: 20px 0;

    input {
        height: 35px;
        font-size: 14px;
        width: -webkit-fill-available;

        border-radius: 10px;
        border: 1px solid $border-color;

        &:valid + .placeholder,
        &:focus + .placeholder {
            top: -7px;
            left: 15px;
            font-size: 12px;
            color: #666;
            z-index: 10;
            background: white;
            //background-color: white; // Use the background color of the container if needed
        }

        &:focus {
            outline: none;
            border-color: #666;
        }
    }

    .placeholder {
        position: absolute;
        top: 8px;
        left: 10px;
        padding: 0 4px;
        //background-color: white;
        transition: all 0.2s ease;
        pointer-events: none;
        font-size: 16px;
        color: $placeholder-color;
    }
}

h2.modal-title {
    font-family: $font-family-title;
    font-style: normal;
    font-weight: 800;
    font-size: 42px;
    line-height: 47px;
    color: #2c2c2c;
}

span.modal-caption {
    font-family: $font-family-caption;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #2c2c2c;
}

span.placeholder {
    margin-left: 10px;
    font-family: $font-family-caption;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}

.reg-bg {
    width: inherit;
    height: 100%;
    //margin: 10% auto;
    padding: 50px 30px;
    border-radius: 2px;
    box-shadow: 0 2px 8px $box-shadow-color;
}

@media(max-width: 765px) {
    .form-grid {
        grid-template-columns: 1fr;
    }
    .modal-mask {
        overflow-y: hidden;
    }
    .modal-container {
        width: 100%;
        height: 100% !important;
        margin: 0;
        padding: 20px 40px;
        overflow-y: scroll;
    }
    .btn {
        width: 100%;
        height: 35px;
    }
    .reg-bg {
        padding: 50px;
        height: 100vh;
    }
}

.qr-code-wrapper {
    display: flex;
    justify-content: center;
    background-color: #ffdd5d;
    background-clip: content-box;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.cr-white-bg {
    padding: 100px;
    position: relative; /* Required for pseudo-element positioning */
    z-index: 1;

    &::after {
        content: '';
        position: absolute;
        top: -100px;
        left: 20px;
        width: calc(100% + 20%);
        height: calc(100% + 50%);
        background-color: #ffffff;
        z-index: 0;
        transform: rotate(20deg);
    }
}

.qr-code {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

.qr-code-text-container {
    text-align: left;
    margin-left: 40px;
    margin-top: 60px;
    width: 50%;
    height: 100%;
}

.qr-title {
    font-size: 20px;
    font-family: $font-family-main;
    font-weight: bold;
    color: white;
}

.qr-amount {
    font-size: 36px;
    font-family: $font-family-title;
    margin-top: 30px;
    color: white;
}

@media(max-width: 765px) {
    .qr-code-wrapper {
        /* margin: 10% auto; */
        display: block;
        width: 100%;
        height: 100%;
        background-color: #ffdd5d;
        background-clip: content-box;
        position: unset;
    }
    .cr-white-bg {
        padding: 50px;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #ffffff;
            z-index: 0;
            transform: rotate(0);
        }
    }
}

</style>