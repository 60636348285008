<script>
    export default {
        name: 'BestMedicalBlock',
        props: ['isMobile'],
        data() {
            return {
                toggle: true
            }
        },
        methods: {
            showMedOfficer() {
                this.toggle = true
            },
            showDoctor() {
                this.toggle = false
            }
        }
    }
</script>

<template>
<div id="nominations" class="bg" style="margin-bottom: 40px">
    <h2  class="block-title">НОМИНАЦИЯ</h2>
<!-- PC -->
    <template v-if="!isMobile">
        <div class="d-flex justify-center gap-50">
            <button :class="toggle ? 'custom-btn active' : 'custom-btn'" @click="showMedOfficer">THE BEST MEDICAL OFFICER 2024</button>
            <button :class="!toggle ? 'custom-btn active' : 'custom-btn'" @click="showDoctor">THE BEST DOCTOR 2024</button>
        </div>
        <!-- THE BEST MEDICAL OFFICER -->
        <template v-if="toggle">
            <div class="d-flex justify-center">
                <p class="badge" style="line-height: 38px">Уважаемые руководители медицинских учреждений,
                    приглашаем вас принять участие в номинации 
                    THE BEST MEDICAL OFFICER 2024 
                    Kazakhstan Growth Forum.
                </p>
                <!-- <img style="width: 50%; position: absolute; top: 0; right: 0; z-index: -1" src="../assets/fon_1%202.png" alt=""> -->
            </div>

            <h2 class="block-title  mb-10 mt-5">Критерии оценки:</h2>
            <div class="container pt-0 pb-0" style="color: white; ">
                <span class="uppercase"><img style="margin-right: 10px" src="../assets/ads_click.png" alt="">    запущенные процессы для эффективного оказания медицинской помощи населению;</span><br><br>
                <span class="uppercase"><img style="margin-right: 10px" src="../assets/ads_click.png" alt="">    внедренные процессы для оптимизации/улучшения взаимодействия между персоналом;</span><br><br>
                <span class="uppercase"><img style="margin-right: 10px" src="../assets/ads_click.png" alt="">    инновационные технологии, запущенные в медицинском учреждении;</span><br><br>
                <span class="uppercase"><img style="margin-right: 10px" src="../assets/ads_click.png" alt="">    достижения в работе по итогам 2023 года.</span><br><br>
            </div>
            <div class="d-flex justify-center mb-10">
                <h2 class="block-title mb-0">СРОК ПОДАЧИ ЗАЯВОК – ДО 24 МАЯ 2024 ГОДА</h2>
            </div>
            <div class="d-flex justify-center direction-column align-items-center">
                <p class="mb-10"><i>Участие в номинации бесплатное, по предварительной заявке</i></p>

                <h2 class="gradient">Состав Экспертного совета</h2>

                <div class="speakers--wrapper-4">
                    <div class="speaker--wrapper">
                        <img src="../assets/speakers/1.png" alt="">
                        <div class="d-flex justify-center align-items-center direction-column">
                            <span class="speaker-name">Айгуль<br>ДЖАКЕЛОВА</span>
                            <span class="speaker-position">к.м.н., CEO SOS Medical Assistance</span>
                        </div>
                    </div>
                    <div class="speaker--wrapper">
                        <img src="../assets/speakers/34.png" alt="">
                        <div class="d-flex justify-center align-items-center direction-column">
                            <span class="speaker-name">Назым<br>БЕКТУРГАНОВА</span>
                            <span class="speaker-position">к.м.н.,<br>главный внештатный стоматолог МЗ РК,<br>Президент Единой Казахстанской<br>Ассоциации стоматологов</span>
                        </div>
                    </div>
                    <div class="speaker--wrapper">
                        <img src="../assets/speakers/5.png" alt="">
                        <div class="d-flex justify-center align-items-center direction-column">
                            <span class="speaker-name">Вячеслав<br>ЛОКШИН</span>
                            <span class="speaker-position">д.м.н., академик НАН РК, <br>CEO «PERSONA»</span>
                        </div>
                    </div>
                    <div class="speaker--wrapper">
                        <img src="../assets/speakers/10.png" alt="">
                        <div class="d-flex justify-center align-items-center direction-column">
                            <span class="speaker-name">Алмаз<br>ШАРМАН</span>
                            <span class="speaker-position">член Американской Ассоциации Здравоохранения, президент Академии профилактической медицины Казахстана и Национального центра здорового питания</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-center mb-20">
                <a href="https://forms.gle/DcWCcmZcioqtXJNC8" target="_blank" class="btn btn-gradient" style="padding: 10px 140px;font-size: 26px;width: 500px;">УЧАСТВОВАТЬ</a>
            </div>
        </template>
        <!-- THE BEST DOCTOR -->
        <template v-else-if="!toggle">
            <div class="d-flex justify-center">
                <p class="badge" style="line-height: 38px">Уважаемые врачи, приглашаем вас принять участие
                    в номинации THE BEST DOCTOR 2024 Kazakhstan Growth Forum.
                </p>
                <img style="width: 50%; position: absolute; top: 0; right: 0; z-index: -1" src="../assets/group.png" alt="">
            </div>
            <h2 class="block-title mb-10 mt-5">Критерии оценки:</h2>
            <div class="container pt-0 pb-0" style="color: white;">
                <span class="uppercase"><img style="margin-right: 10px" src="../assets/ads_click.png" alt="">    личный вклад в развитие медицины и оказание медицинской помощи населению;</span><br><br>
                <span class="uppercase"><img style="margin-right: 10px" src="../assets/ads_click.png" alt="">    инновационные технологии, используемые в работе;</span><br><br>
                <span class="uppercase"><img style="margin-right: 10px" src="../assets/ads_click.png" alt="">    отзывы от пациентов (в случае наличия).</span><br><br>
            </div>
            <div class="d-flex justify-center mb-10">
                <h2 class="block-title mb-0">СРОК ПОДАЧИ ЗАЯВОК – ДО 24 МАЯ 2024 ГОДА</h2>
            </div>
            <div class="d-flex justify-center direction-column align-items-center">
                <p class="mb-10"><i>Участие в номинации бесплатное, по предварительной заявке</i></p>

                <h2 class="gradient">Состав Экспертного совета</h2>

                <div class="speakers--wrapper-4">
                    <div class="speaker--wrapper">
                        <img src="../assets/speakers/1.png" alt="">
                        <div class="d-flex justify-center align-items-center direction-column">
                            <span class="speaker-name">Айгуль<br>ДЖАКЕЛОВА</span>
                            <span class="speaker-position">к.м.н., CEO SOS Medical Assistance</span>
                        </div>
                    </div>
                    <div class="speaker--wrapper">
                        <img src="../assets/speakers/34.png" alt="">
                        <div class="d-flex justify-center align-items-center direction-column">
                            <span class="speaker-name">Назым<br>БЕКТУРГАНОВА</span>
                            <span class="speaker-position">к.м.н.,<br>главный внештатный стоматолог МЗ РК,<br>Президент Единой Казахстанской<br>Ассоциации стоматологов</span>
                        </div>
                    </div>
                    <div class="speaker--wrapper">
                        <img src="../assets/speakers/5.png" alt="">
                        <div class="d-flex justify-center align-items-center direction-column">
                            <span class="speaker-name">Вячеслав<br>ЛОКШИН</span>
                            <span class="speaker-position">д.м.н., академик НАН РК, <br>СEO PERSONA</span>
                        </div>
                    </div>
                    <div class="speaker--wrapper">
                        <img src="../assets/speakers/10.png" alt="">
                        <div class="d-flex justify-center align-items-center direction-column">
                            <span class="speaker-name">Алмаз<br>ШАРМАН</span>
                            <span class="speaker-position">член Американской Ассоциации Здравоохранения, президент Академии профилактической медицины Казахстана и Национального центра здорового питания</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-center mb-20">
                <a href="https://forms.gle/FWXQ1785RKcJdaHH6" target="_blank" class="btn btn-gradient" style="padding: 10px 140px;font-size: 26px;width: 500px;">УЧАСТВОВАТЬ</a>
            </div>
        </template>
    </template>
<!-- PHONE -->
    <template v-else>
<!-- THE BEST MEDICAL OFFICER 2024 -->
        <div class="d-flex justify-center gap-50">
            <button class="custom-btn active mb-30">
                <a href="https://forms.gle/DcWCcmZcioqtXJNC8" target="_blank">THE BEST MEDICAL OFFICER 2024 </a>
            </button>
        </div>

        <div>
            <div class="d-flex justify-center">
                <p class="badge" style="line-height: 38px">Уважаемые руководители медицинских учреждений,
                    приглашаем вас принять участие в номинации 
                    THE BEST MEDICAL OFFICER 2024 
                    Kazakhstan Growth Forum.
                </p>
                <!-- <img style="width: 50%; position: absolute; top: 0; right: 0; z-index: -1" src="../assets/fon_1%202.png" alt=""> -->
            </div>

            <h2 class="block-title  mb-10 mt-5">Критерии оценки:</h2>
            <div class="container pt-0 pb-0" style="color: white; ">
                <span class="uppercase"><img style="margin-right: 10px" src="../assets/ads_click.png" alt="">    запущенные процессы для эффективного оказания медицинской помощи населению;</span><br><br>
                <span class="uppercase"><img style="margin-right: 10px" src="../assets/ads_click.png" alt="">    внедренные процессы для оптимизации/улучшения взаимодействия между персоналом;</span><br><br>
                <span class="uppercase"><img style="margin-right: 10px" src="../assets/ads_click.png" alt="">    инновационные технологии, запущенные в медицинском учреждении;</span><br><br>
                <span class="uppercase"><img style="margin-right: 10px" src="../assets/ads_click.png" alt="">    достижения в работе по итогам 2023 года.</span><br><br>
            </div>
        </div>
<!-- THE BEST DOCTOR 2024 -->
        <div class="d-flex justify-center gap-50">
            <button class="custom-btn active mb-30">
                <a href="https://forms.gle/FWXQ1785RKcJdaHH6" target="_blank">THE BEST DOCTOR 2024 </a>
            </button>
        </div>

        <div>
            <div class="d-flex justify-center">
                <p class="badge" style="line-height: 38px">Уважаемые врачи, приглашаем вас принять участие
                    в номинации THE BEST DOCTOR 2024 Kazakhstan Growth Forum.
                </p>
                <img style="width: 50%; position: absolute; top: 0; right: 0; z-index: -1" src="../assets/group.png" alt="">
            </div>
            <h2 class="block-title mb-10 mt-5">Критерии оценки:</h2>
            <div class="container pt-0 pb-0" style="color: white;">
                <span class="uppercase"><img style="margin-right: 10px" src="../assets/ads_click.png" alt="">    личный вклад в развитие медицины и оказание медицинской помощи населению;</span><br><br>
                <span class="uppercase"><img style="margin-right: 10px" src="../assets/ads_click.png" alt="">    инновационные технологии, используемые в работе;</span><br><br>
                <span class="uppercase"><img style="margin-right: 10px" src="../assets/ads_click.png" alt="">    отзывы от пациентов (в случае наличия).</span><br><br>
            </div>
            <div class="d-flex justify-center mb-10">
                <h2 class="block-title mb-0">СРОК ПОДАЧИ ЗАЯВОК – ДО 24 МАЯ 2024 ГОДА</h2>
            </div>
            <div class="d-flex justify-center direction-column align-items-center">
                <p class="mb-10"><i>Участие в номинации бесплатное, по предварительной заявке</i></p>

                <h2 class="gradient">Состав Экспертного совета</h2>

                <div class="speakers--wrapper-4">
                    <div class="speaker--wrapper">
                        <img src="../assets/speakers/1.png" alt="">
                        <div class="d-flex justify-center align-items-center direction-column">
                            <span class="speaker-name">Айгуль<br>ДЖАКЕЛОВА</span>
                            <span class="speaker-position">к.м.н., CEO SOS Medical Assistance</span>
                        </div>
                    </div>
                    <div class="speaker--wrapper">
                        <img src="../assets/speakers/34.png" alt="">
                        <div class="d-flex justify-center align-items-center direction-column">
                            <span class="speaker-name">Назым<br>БЕКТУРГАНОВА</span>
                            <span class="speaker-position">к.м.н.,<br>главный внештатный стоматолог МЗ РК,<br>Президент Единой Казахстанской<br>Ассоциации стоматологов</span>
                        </div>
                    </div>
                    <div class="speaker--wrapper">
                        <img src="../assets/speakers/5.png" alt="">
                        <div class="d-flex justify-center align-items-center direction-column">
                            <span class="speaker-name">Вячеслав<br>ЛОКШИН</span>
                            <span class="speaker-position">д.м.н., академик НАН РК, <br>СEO PERSONA</span>
                        </div>
                    </div>
                    <div class="speaker--wrapper">
                        <img src="../assets/speakers/10.png" alt="">
                        <div class="d-flex justify-center align-items-center direction-column">
                            <span class="speaker-name">Алмаз<br>ШАРМАН</span>
                            <span class="speaker-position">член Американской Ассоциации Здравоохранения, президент Академии профилактической медицины Казахстана и Национального центра здорового питания</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    
</div>
</template>

<style scoped lang="scss">
.bg {
    background: url(../assets/nomination-bg.png);
    background-size: cover;
    padding: 1px 50px;
    @media (max-width: 768px) {
        background: #00464e;
    }
}
.custom-btn {
    width: 40%;
    padding: 10px 0;
    background-color: #727376;
    border-radius: 21px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        width: 100%;
        height: 90px;
    }
}
.active {
    background: linear-gradient(90deg, #6F6FD7 0%, #CB26A3 100%);
}
.block-title {
    color: white;

}

.badge {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    background-image: none;
    padding: 30px;
    @media (max-width: 768px) {
        width: 100%;
    }
}
a {
    text-decoration: none;
    color: white;
}
</style>