<script>
export default {
    name: 'ProgramBlock',
    props: ['isMobile']
}
</script>

<template>
    <div id="program" class="program-bg mb-30">
        <div class="container">
            <div class="d-flex justify-center gap-30 bg">
                <div class="program-title">
                    <span v-if="!isMobile">ПРОГРАММА</span>
                    <br>
                    <span class="year">2024</span>
                </div>
                <div>
                    <img src="../assets/logo.png" alt="логотип" style="width: 220px">
                </div>
            </div>
            <br>
            <br>
            <details>
                <summary class="program-block">
                    <h3>Открытие
                        <!--                        <img src="../assets/arrow.png" alt="">-->
                    </h3>
                    <!--                    <br>-->
                </summary>

                <div class="program--wrapper">
                    <div class="program-time">08:00</div>
                    <div class="program-name">WELCOME COFFEE. РЕГИСТРАЦИЯ.</div>
                </div>
                <div class="program--wrapper">
                    <div class="program-time">09:00</div>
                    <div>
                        <span class="program-name">приветственное слово</span>
                        <br>
                        <span class="program-name">Айгуль Джакелова,</span> <span class="program-caption">к.м.н., SOS Medical Assistance</span>
                        <br>
                        <br>
                    </div>
                </div>
            </details>
            <details>
                <summary>
                    <h3 class="program-block">Доклады</h3>
                </summary>
                <div class="program--wrapper">
                    <div class="program-time">09:10</div>
                    <div>
                        <span class="program-name">ТЕМА: «Стигма и предрассудки в медицинском сообществе: барьеры, последствия и интервенции»</span>
                        <br>
                        <span class="program-name">Асель ТЕРЛИКБАЕВА,</span> <span class="program-caption">СЕО ЦИГЗЦА при колумбийском университете</span>
                        <br><br>
                    </div>
                </div>
                <div class="program--wrapper">
                    <div class="program-time">09:30</div>
                    <div>
                        <span class="program-name">ТЕМА: «Изолированные миры или стратегические партнёры? Welness  в системе здравоохранения»</span>
                        <br>
                        <span class="program-name">Айгуль ДЖАКЕЛОВА,</span> <span class="program-caption">к.м.н., SOS Medical Assistance</span> <br><br>
                    </div>
                </div>
                <div class="program--wrapper">
                    <div class="program-time">09:45</div>
                    <div>
                        <span class="program-name">ТЕМА: «Грамотность в вопросах здоровья и вмешательства для ее повышения на местном и глобальном уровнях»</span>
                        <br>
                        <span class="program-name">Алтын АРИНГАЗИНА,</span> <span class="program-caption">д.м.н., эксперт ВОЗ, президент Фулбрайтовской Ассоциации Казахстана</span> <br><br>
                    </div>
                </div>
                <div class="program--wrapper">
                    <div class="program-time">11:25 </div>
                    <div>
                        <span class="program-name">ТЕМА: «Как эффективно управлять весом, оздоровиться и предупредить болезни»</span>
                        <br>
                        <span class="program-name">Алмаз ШАРМАН,</span> <span class="program-caption">член Американской Ассоциации Здравоохранения, президент Академии профилактической медицины Казахстана</span> <br><br>
                    </div>
                </div>
                <div class="program--wrapper">
                    <div class="program-time">11:45</div>
                    <div>
                        <span class="program-name">ТЕМА: «Тренд «Быть здоровым»</span>
                        <br>
                        <span class="program-name">Валихан ТЕН,</span> <span class="program-caption">CEO KRIDA, 2-хкратный чемпион мира по пауэрлифтингу</span> <br><br>
                    </div>
                </div>
                <div class="program--wrapper">
                    <div class="program-time">12:05</div>
                    <div>
                        <span class="program-name">ТЕМА: «Этические проблемы в современной медицине»</span>
                        <br>
                        <span class="program-name">Вячеслав ЛОКШИН,</span> <span class="program-caption">д.м.н., академик НАН РК, СЕО PERSONA</span> <br><br>
                    </div>
                </div>
                <div class="program--wrapper">
                    <div class="program-time">12:25</div>
                    <div>
                        <span class="program-name">ТЕМА: «Как искусственный интеллект помогает усилить коммуникации с пациентами для медицинских организаций»</span>
                        <br>
                        <span class="program-name">Василий МЯСНИКОВ,</span> <span class="program-caption">CEO BP Contact</span> <br><br>
                    </div>
                </div>
                <div class="program--wrapper">
                    <div class="program-time">12:45</div>
                    <div>
                        <span class="program-name">ТЕМА: «ДНК и ее роль в предиктивной и персонализированной медицине»</span>
                        <br>
                        <span class="program-name">Вячеслав БЕЛОУСОВ,</span> <span class="program-caption">CEO TREEGENE, к.б.н.</span> <br><br>
                    </div>
                </div>
                <div class="program--wrapper">
                    <div class="program-time">14:15</div>
                    <div>
                        <span class="program-name">ТЕМА: «Первичное здравоохранение - возможности для инвестиций»</span>
                        <br>
                        <span class="program-name">Елжан БИРТАНОВ,</span> <span class="program-caption">советник в Tansar Capital и KazMedConsult</span>
                        <br><br>
                    </div>
                </div>
                <div class="program--wrapper">
                    <div class="program-time">15:35</div>
                    <div>
                        <span class="program-name">ТЕМА: «Казахстанские медтех проекты»</span>
                        <br>
                        <span class="program-name">Бексултан ШАКИМОВ,</span> <span class="program-caption">операционный директор Cerebra AI</span> <br><br>
                    </div>
                </div>
                <div class="program--wrapper">
                    <div class="program-time">17:15</div>
                    <div>
                        <span class="program-name">ТЕМА: «ИИ и инновации в диагностике и лечении деменций»</span>
                        <br>
                        <span class="program-name">Жибек ЖОЛДАСОВА,</span> <span class="program-caption">директор UBC, врач-психотерапевт</span> <br><br>
                    </div>
                </div>
            </details>
            <details>
                <summary>
                    <h3 class="program-block">ОБЕД, КОФЕ-БРЕЙК</h3>
                </summary>
                <div class="program--wrapper">
                    <div class="program-time">11:10</div>
                    <div>
                        <span class="program-name">КОФЕ-БРЕЙК</span>
                    </div>
                </div>
                <div class="program--wrapper">
                    <div class="program-time">13:05</div>
                    <div>
                        <span class="program-name">ОБЕД</span>
                    </div>
                </div>
                <div class="program--wrapper">
                    <div class="program-time">15:55</div>
                    <div>
                        <span class="program-name">КОФЕ-БРЕЙК</span>
                    </div>
                    <br><br>
                </div>
            </details>
            <details>
                <summary>
                    <h3 class="program-block">вручение наград</h3>
                </summary>
                <div class="program--wrapper">
                    <div class="program-time">14:20</div>
                    <div>
                        <span class="program-name">вручение наград</span>
                        <br>
                        <span class="program-caption">
                            THE BEST MEDICAL OFFICER 2024 И <br> THE BEST DOCTOR 2024
                        </span> <br><br>
                    </div>
                </div>
            </details>
            <details>
                <summary>
                    <h3 class="program-block">ПАНЕЛЬНЫЕ ДИСКУССИИ</h3>
                </summary>
                <div class="program--wrapper">
                    <div class="program-time">10:05</div>
                    <div>
                        <span class="program-name--case-none">ТЕМА: «Что такое Health promotion и health literacy? Как повысить их эффективность и зачем?»</span> <br>
                        <span class="program-name--case-none">Модератор: Алимжан ЕСКАРАЕВ, </span>
                        <span class="program-caption">СEO ProZdrav.kz</span> <br>
                        <span class="program-name--case-none">Спикеры:</span> <br>
                        <span class="program-name--case-none">Алтын АРИНГАЗИНА, </span>
                        <span class="program-caption">д.м.н., эксперт ВОЗ, президент Фулбрайтовской Ассоциации Казахстана</span><br>
                        <span class="program-name--case-none">Жулдыз КОЖАХАНОВА, </span>
                        <span class="program-caption">основатель GalaMilk</span> <br>
                        <span class="program-name--case-none">Асель ТЕРЛИКБАЕВА, </span>
                        <span class="program-caption">СЕО ЦИГЗЦА при колумбийском университете </span> <br>
                        <span class="program-name--case-none">Жибек ЖОЛДАСОВА, </span>
                        <span class="program-caption">директор UBC, врач-психотерапевт</span> <br>
                        <span class="program-name--case-none">Валихан ТЕН, </span>
                        <span class="program-caption">CEO KRIDA, 2-хкратный чемпион мира по пауэрлифтингу</span> <br>
                    </div>
                </div>
                <br><br>
                <div class="program--wrapper">
                    <div class="program-time">14:35</div>
                    <div>
                        <span class="program-name--case-none">ТЕМА: «ПМСП: как снизить нагрузку на медицину первичного звена?»</span> <br>
                        <span class="program-name--case-none">Модератор: Вячеслав ЛОКШИН, </span>
                        <span class="program-caption">д.м.н., академик НАН РК, СЕО PERSONA</span> <br>
                        <span class="program-name--case-none">Спикеры:</span> <br>
                        <span class="program-name--case-none">Амантур РЫСКУЛОВ, </span>
                        <span class="program-caption">СЕО Diaverum Казахстан</span> <br>
                        <span class="program-name--case-none">Асем МУСАБАЕВА, </span>
                        <span class="program-caption">к.м.н., директор ГКП на ПХВ «Городская поликлиника №9»</span> <br>
                        <span class="program-name--case-none">Лейла ДАИРБАЕВА, </span>
                        <span class="program-caption">директор сети МЦ эпилептологии и ЭЭГ</span> <br>
                        <span class="program-name--case-none">Жамиля АБЕУОВА, </span>
                        <span class="program-caption">директор ГКП на ПХВ Енбекшиказахская многопрофильная ЦРБ</span> <br>
                        <span class="program-name--case-none">Райымбек ТАСЫРБАЕВ, </span>
                        <span class="program-caption">руководитель ГП ТО</span> <br>
                    </div>
                </div>
                <br><br>
                <div class="program--wrapper">
                    <div class="program-time">16:15</div>
                    <div>
                        <span class="program-name--case-none">ТЕМА: «Здоровье казахстанцев как основа безопасности страны»</span> <br>
                        <span class="program-name--case-none">Модератор: Вячеслав ПОЦЕЛУЙКО, </span>
                        <span class="program-caption">советник председателя правления АО КСЖ Коммеск</span> <br>
                        <span class="program-name--case-none">Спикеры:</span> <br>
                        <span class="program-name--case-none">Елена ЛИ, </span>
                        <span class="program-caption">СЕО «Дольче Фарм»</span> <br>
                        <span class="program-name--case-none">Галымжан ТОГИЗБАЕВ, </span>
                        <span class="program-caption">Председатель Республиканского общественного объединения  "Казахская коллегия ревматологии"</span><br>
                        <span class="program-name--case-none">Евгений МУХАМЕДЖАНОВ, </span>
                        <span class="program-caption">управляющий директор Казахстанской фондовой биржи, основатель ECO Network</span> <br>
                        <span class="program-name--case-none">Ерлан СУЛЕЙМЕНОВ, </span>
                        <span class="program-caption">CEO КДЛ «Олимп»</span> <br>
                    </div>
                </div>
                <br>
            </details>
            <details>
                <summary>
                    <h3 class="program-block">ЗАКРЫТИЕ</h3>
                </summary>
                <div class="program--wrapper">
                    <div class="program-time">18:00</div>
                    <div>
                        <span class="program-name">Айгуль Джакелова, </span>
                        <span class="program-caption">к.м.н., CEO SOS Medical Assistance</span>
                    </div>
                </div>
                <br>
                <br>
            </details>

            <div class="d-flex justify-center">
                <a target="_blank" href="https://kgf.cic.kz/api/outsystems/download-program" class="btn btn-gradient">ПРОГРАММА</a>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.bg {
    background-image: url('../assets/program2.png');
    background-size: cover;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    padding: 20px 0;
    @media (max-width: 768px) {
        background-image: url('../assets/program3.png');
    }
}

.program-bg .container {
    padding-bottom: 0;
}

details[open] summary ~ * {
    animation: open 0.3s ease-in-out;
}

@keyframes open {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

details summary::-webkit-details-marker {
    display: none;
    color: transparent;
}


details summary:after {
    content: url("../assets/arrow.png");
    /* position: absolute; */
    /* font-size: 1.75rem; */
    /* line-height: 0; */
    /* margin-top: 0.75rem; */
    /* right: 0; */
    font-weight: 200;
    transform-origin: center;
    transition: 200ms linear;
}

details[open] summary:after {
    transform: rotate(180deg);
    font-size: 2rem;
}

details summary::marker {
    display: none;
}

details h3 {
    outline: 0;
}

summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>