<script>
    import Modal from "@/components/Modal.vue";
    import axios from "axios";

    export default {
        name: 'PartnerBlock',
        props: ['isMobile'],
        components: {Modal},
        data() {
            return {
                showModal: false,
                first_name: '',
                last_name: '',
                phone: '',
                company: '',
                comments: '',
                smi_name: '',
                email: '',
                type: 0,
                title: 'Cтать партнером',
                to_email: '',
                loading: false,
            }
        },
        methods: {
            showMessage(message, type) {
                this.$toast.show(message, {
                    type: type,
                    duration: 2500,
                    position: 'top'
                })
            },
            send() {
                this.loading = true;
                let message = "";

                if (this.type != 3) {
                    if (this.last_name.length == 0 || this.first_name.length == 0 || this.phone.length == 0 || this.company.length == 0) {
                        this.showMessage('Заполните все поля', 'error')
                        this.loading = false
                        return;
                    }
                    message = "Фамилия: " + this.last_name + "\nИмя: " + this.first_name + "\nНомер телефона: " + this.phone + "\nНазвание компании: " + this.company + "\nКомментарий: "+this.comments;
                } else {
                    if (this.last_name.length == 0 || this.first_name.length == 0 || this.phone.length == 0 || this.smi_name.length == 0 || this.email.length == 0) {
                        this.showMessage('Заполните все поля', 'error')
                        this.loading = false
                        return;
                    }
                    message = "Фамилия: " + this.last_name + "\nИмя: " + this.first_name + "\nНомер телефона: " + this.phone +"\nE-mail: " + this.email +"\nНазвание СМИ: " + this.smi_name + "\nКомментарий: "+this.comments;
                }
                let data = {
                    email: this.to_email,
                    title: this.title,
                    name: 'KHF 2024',
                    message: message,
                }
                axios.post('https://kgf.cic.kz/api/send-custom-mail', data).then(() => {
                    this.close();
                    this.showMessage('Заявка успешно отправлен', 'success')
                    this.loading = false;
                })
            },
            close() {
                this.showModal = false;
                this.first_name = '';
                this.last_name = '';
                this.phone = '';
                this.company = '';
                this.comments = '';
                this.smi_name = '';
                this.email = '';
                this.type = 0;
                this.title = 'Cтать партнером';
                this.to_email = '';
                this.loading = false;
            },
            openModal(type) {
                this.loading = false;
                this.showModal = true;
                this.type = type;
                if(this.type === 1) {
                    this.title = 'Стать спикером'
                    this.to_email = 'a.dzhakelova@sosmed.kz'
                } else if(this.type === 2) {
                    this.title = 'Cтать партнером'
                    this.to_email = 'a.dzhakelova@sosmed.kz'
                } else {
                    this.title = 'Аккредитация СМИ'
                    this.to_email = 'marketing@cic.kz'
                }
            }
        }
    }
</script>

<template>
    <div id="partners">
        <h2 class="block-title">ОРГАНИЗАТОРЫ</h2>

        <div class="container pt-0 pb-0">

            <modal :show="showModal" @close="showModal = false">
                <template #body>
                    <span class="close" style="color:black" @click="close()">x</span>
                    <div class="reg-bg">
                        <h2 class="modal-title">{{title}}</h2>
                        <span class="modal-caption"></span>
                        <form @submit.prevent="send">
                            <div class="form-grid">
                                <div class="form-field">
                                    <input type="text" id="last_name" v-model="last_name" required maxlength="30">
                                    <label for="last_name" class="placeholder">Фамилия *</label>
                                </div>
                                <div class="form-field">
                                    <input type="text" id="last_name" v-model="first_name" required maxlength="30">
                                    <label for="last_name" class="placeholder">Имя *</label>
                                </div>
                                <div class="form-field">
                                    <input type="text" id="last_name" v-model="phone" required maxlength="30">
                                    <label for="last_name" class="placeholder">Номер телефона *</label>
                                </div>
                                <div class="form-field" v-if="type==3">
                                    <input type="text" id="last_name" v-model="email" required maxlength="30">
                                    <label for="last_name" class="placeholder">Почта *</label>
                                </div>
                                <div class="form-field" v-if="type!=3">
                                    <input type="text" id="last_name" v-model="company" required maxlength="30">
                                    <label for="last_name" class="placeholder">Название компании *</label>
                                </div>
                                <div class="form-field" v-if="type==3">
                                    <input type="text" id="last_name" v-model="smi_name" required maxlength="30">
                                    <label for="last_name" class="placeholder">Название СМИ *</label>
                                </div>
                                <div class="form-field" v-if="type!=3">
                                    <textarea rows="3" id="last_name" v-model="comments" style="padding-top: 10px">



                                    </textarea>
                                    <label for="last_name" class="placeholder">Комментарии (необязательно)</label>
                                </div>
                                <button class="btn" v-on:click="send" :disabled="loading">Отправить</button>
                            </div>
                        </form>
                    </div>
                </template>
            </modal>

            <div class="d-flex justify-around mb-20">
                <img src="../assets/logo%20KGF_black.png" alt="Kazakhstan Growth Forum">
                <img src="../assets/111.png" alt="SOS Medical Assitance">
                <img src="../assets/Logo%20centras%20(1).png" alt="Centras Insurance">
            </div>
        </div>

        <h2 class="block-title" style="text-transform: uppercase">Информационные партнеры</h2>

        <div class="container" style="padding: 0;">
            <div class="d-flex justify-around mb-30" :class="{'flex-col gap-10': isMobile}">
                <button class="btn btn-gradient" v-on:click="openModal(1)">СТАТЬ ПАРТНЕРОМ</button>
                <button class="btn btn-gradient" v-on:click="openModal(2)">СПОНСОРКИЙ ПАКЕТ</button>
                <button class="btn btn-gradient" v-on:click="openModal(3)">АККРЕДИТАЦИЯ СМИ</button>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

$primary-color: #2B255C;
$modal-bg-color: rgba(0, 0, 0, 0.5);
$box-shadow-color: rgba(0, 0, 0, 0.33);
$border-color: #CFD3D8;
$btn-bg-color: #CD50AE;
$btn-border-color: #ee64cb;
$hover-shadow-color: #99bdff;
$font-family-main: 'Montserrat', sans-serif;
$font-family-title: 'PT Sans Caption', sans-serif;
$font-family-caption: 'GT Eesti Display Trial', sans-serif;
$focus-border-color: #666;
$placeholder-color: #aaa;
.form-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    row-gap: 20px;
}

label.accept-font {
    font-family: $font-family-title;
    font-style: normal;
    cursor: pointer;
}

input[type="checkbox"] {
    width: 15px;
    background: #FFFFFF;
    border: 1px solid $border-color;
    box-sizing: border-box;
    border-radius: 6px;
    margin-right: 10px;
}

.form-field {
    position: relative;
    //margin: 20px 0;

    input, textarea {
        height: 35px;
        font-size: 14px;
        width: -webkit-fill-available;

        border-radius: 10px;
        border: 1px solid $border-color;

        &:valid + .placeholder,
        &:focus + .placeholder {
            top: -7px;
            left: 15px;
            font-size: 12px;
            color: #666;
            z-index: 10;
            background: white;
        }

        &:focus {
            outline: none;
            border-color: #666;
        }
    }

    .placeholder {
        position: absolute;
        top: 8px;
        left: 10px;
        padding: 0 4px;
        //background-color: white;
        transition: all 0.2s ease;
        pointer-events: none;
        font-size: 16px;
        color: $placeholder-color;
    }
}

h2.modal-title {
    font-family: $font-family-title;
    font-style: normal;
    font-weight: 800;
    font-size: 42px;
    line-height: 47px;
    color: #2c2c2c;
}

span.modal-caption {
    font-family: $font-family-caption;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #2c2c2c;
}

span.placeholder {
    margin-left: 10px;
    font-family: $font-family-caption;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}

.reg-bg {
    width: inherit;
    height: 100%;
    //margin: 10% auto;
    padding: 50px 30px;
    border-radius: 2px;
    box-shadow: 0 2px 8px $box-shadow-color;
}

@media(max-width: 765px) {
    .form-grid {
        grid-template-columns: 1fr;
    }
    .modal-mask {
        overflow-y: hidden;
    }
    .modal-container {
        width: 100%;
        height: 100% !important;
        margin: 0;
        padding: 20px 40px;
        overflow-y: scroll;
    }
    .btn {
        width: 100%;
        height: 35px;
    }
    .reg-bg {
        padding: 50px;
        height: 100vh;
    }
}
</style>