<script>
    export default {
        name: 'HeaderBlock'
    }
</script>

<template>
    <div class="header">
        <div class="">
            <nav>
                <ul>
                    <li><a href="#about">О Форуме</a></li>
                    <li><a href="#speakers">Спикеры</a></li>
                    <li><a href="#program">Программа</a></li>
                    <li><a href="#nominations">Номинация</a></li>
                    <li><a href="#gallery">Как это было</a></li>
                    <li><a href="#partners">Партнеры</a></li>
                    <li><a href="#footer">Контакты</a></li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<style scoped>
@media (max-width: 768px) {
    nav {
        margin-top: 20%;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: baseline
    }
    nav ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}
 
</style>