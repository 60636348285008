import { createApp } from 'vue'
import App from './App.vue'
import Toaster from "@meforma/vue-toaster";
import Maska from 'maska'
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const app = createApp(App)

const vuetify = createVuetify({
    components,
    directives,
  })

app.use(Toaster)
    .use(Maska)
    .use(vuetify)
    .mount('#app')