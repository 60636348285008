<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: "ModalFinal",
    props: {
        show: Boolean,
    },
    emits: ['close'],
});
</script>

<template>
    <Transition name="modal">
        <div v-if="show" class="modal-mask">
            <div class="modal-container">
                <div class="modal-body">
                    <span class="close z2"
                          @click="$emit('close')">x
                    </span>

                    <slot name="body">default body</slot>
                </div>
            </div>
        </div>
    </Transition>
</template>

<style>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    transition: opacity 0.3s ease;
}

.modal-container {
    width: 768px;
    margin: auto;
    //padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    position: relative;
}
.close {
    position: absolute;
    top:0;
    right: 10px;
    cursor: pointer;
    font-size: 40px;
    font-family: "PT Sans Caption";
    color: black;
    z-index: 2;
    background-color: white;
}
@media(max-width: 765px) {
    .modal-container {
        width: 100%;
        margin: 0;
        border-radius: 0;
        max-height: fit-content;
    }
    .modal-body {
        height: 100%;
    }
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 0;
}

.modal-default-button {
    float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
    opacity: 0;
}

.modal-leave-to {
    opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.modal-mask {
    overflow-y: auto;
}

</style>