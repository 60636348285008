<script>
    export default {
        name: 'SectionBlock',
        props: {
            name: {
                type: String,
                default: 'СПИКЕРЫ'
            },
        }
    }
</script>

<template>

    <div class="speaker-section">
        <h2>{{name}}</h2>
    </div>
</template>

<style scoped>

</style>