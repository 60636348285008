<script>
    export default {
        name: 'AboutBlock'
    }
</script>

<template>
    <div id="about" class="about-forum">
        <div class="container">
            <h2>V KAZAKHSTAN HEALTHCARE FORUM 2024</h2> <br>
            <p>
                <span class="uppercase"><b>Здесь создают современное  здравоохранение Казахстана!</b></span><br>
                <span class="uppercase"><b>Здесь влияют на будущее в здравоохранении страны!</b></span><br>
                <span class="uppercase"><b>Здесь находят эффективные решения и делятся лучшими кейсами в здравоохранении!</b></span><br><br>

                <b>Kazakhstan Healthcare Forum</b> – это конструктивный и открытый диалог лидеров сферы здравоохранения в Казахстане. 
                Более 20 спикеров обсудят «боли» системы здравоохранения и поделятся лучшими кейсами и практиками.</p>
            <p>
                <b>Kazakhstan HealthCare Forum 2024</b><br>
                <i>«Тренды и вызовы здравоохранения: Предиктивность. Персонализация. Медицинская грамотность» - <br> V Kazakhstan Healthcare Forum 2024.</i> <br>
                <b>Темы: </b> <br>
                <span style="margin-left:50px;">
                    -	Грамотность в вопросах здоровья на местном и глобальном уровнях
                </span> <br>
                <span style="margin-left:50px;">
                    -	Стигмы и предрассудки в медицинском сообществе
                </span> <br>
                <span style="margin-left:50px;">
                    -	ДНК и ее роль в предиктивной и персонализированной медицине
                </span> <br>
                <span style="margin-left:50px;">
                    -	ИИ и инновации в диагностике и лечении
                </span> <br>
                <span>Мероприятие проводится с 2018 года под эгидой Kazakhstan Growth Forum. <br>
                    Форум пройдет 6 июня 2024 года в офлайн и онлайн формате
                    Регистрация обязательна! Количество мест офлайн ограничено.
                </span>
            </p>
        </div>
        <img style="position: absolute; top: 80px; right: 0; z-index: -1" src="../assets/figure.png" alt="">
    </div>
</template>

<style scoped>
.uppercase{
    text-transform: uppercase;
}
.container {
    //padding: 50px 0;
}
</style>